import React from "react";

import { captureConsoleIntegration } from "@sentry/browser";
import { init, replayIntegration, ErrorBoundary } from "@sentry/react";
import LogRocket from "logrocket";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";

import {
  config_sentryDSN,
  config_REACT_APP_STAGE
} from "./config";
import { APP_STAGE } from "./constants";
import Core from "./pages/Core";
import ReloadPage from "./pages/Error/ReloadPage";
import store from "./redux/store";
import { HideScrollBar } from "./utils/hidescrollbar";

const integrations = [
  // browserTracingIntegration(), // Used for monitoring performance. Not needed for now.
  captureConsoleIntegration({ levels: ["error"] }),
];

if (config_REACT_APP_STAGE !== APP_STAGE.DEV) {
  integrations.push(replayIntegration({
    maskAllText   : false,
    blockAllMedia : false,
  }));
}

init({
  environment              : config_REACT_APP_STAGE,
  dsn                      : config_sentryDSN,
  integrations,
  denyUrls                 : [/node_modules/], // Block errors from node_modules
  sendDefaultPii           : true,
  tracesSampleRate         : 0.2,
  replaysSessionSampleRate : 0.1,
  replaysOnErrorSampleRate : 1.0,
  profilesSampleRate       : 0.0,
  beforeSend(event) {
    // Check if the hostname is localhost
    if (window.location.hostname === "localhost") {
      return null;
    }
    // Filter out common browser extensions errors
    if (event.exception && event.exception.values) {
      const exceptionMessage = event.exception.values[0]?.value;
      if (exceptionMessage && (
        exceptionMessage.includes("chrome-extension://")
        || exceptionMessage.includes("moz-extension://")
        || exceptionMessage.includes("safari-extension://")
      )) {
        return null; // Implication: We won't receive errors caused by browser extensions ie. metamask
      }
    }

    return event;
  },
});

// init logrocket only in production
if (config_REACT_APP_STAGE === "production") {
  LogRocket.init("8gahip/series");
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <HideScrollBar />
        <ErrorBoundary fallback={<ReloadPage />}>
          <SWRConfig value={{
            revalidateIfStale     : false,
            revalidateOnFocus     : false,
            revalidateOnReconnect : false,
            errorRetryCount       : 0
          }}
          >
            <Core />
          </SWRConfig>
        </ErrorBoundary>
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
);
